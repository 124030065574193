<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
<div  v-if="order">
  <b-row>
        <b-card class="mb-1 col-md-6" :title="'Commande #'+order.id">
          <b-card-text>
            <b>Date :</b> {{order.delivered_at}}
          </b-card-text>
          <b-card-text>
            <b>Status : </b>

              <b-badge v-if="order.status=='dispatched'" variant="success">Envoyée</b-badge>
              <b-badge v-else-if="order.status=='scanned'" variant="success">Scans effectués</b-badge>
              <b-badge v-else-if="order.status=='completed'" variant="warning">Terminée</b-badge>
              <b-badge v-else-if="order.status=='printing'" variant="warning">En production</b-badge>
              <b-badge v-else-if="order.status=='delivered'" variant="success">Livrée</b-badge>
              <b-badge v-else-if="order.status=='failed'" variant="danger">échouée</b-badge>
              <b-badge v-else-if="order.status=='canceled'" variant="danger">Annulée</b-badge>
              <b-badge v-else variant="danger">error</b-badge>

        

          </b-card-text>
         
         
           <b-card-text>
            <b>Téléphone :</b> {{order.user.phone}}
          </b-card-text>
        
           
        </b-card>

        <b-card class="mb-1 col-md-6" title="Total">
          
          <b-card-text v-if="order.promotion!=null">
            <b>Promotion : </b>-{{order.promotion.amount}}%
          </b-card-text>
          <b-card-text>
            <b>TOTAL : </b>
            <span v-if="order.promotion!=null">{{order.amount - ((order.amount/100)*order.promotion.amount).toFixed(2)}}€</span>
            <span v-else>{{order.amount}}€</span>
          </b-card-text>
          
        </b-card>
   

  </b-row>
  <b-row>
    
        <b-card class="mb-1 col-md-12" title="Détails commande">
           <b-card-text v-for="(order_item, index) in order.order_items" :key="order_item.id">
            <img :src="photos[index]" width="120" /><br />
            <b>Nom du produit : </b>
            <span v-if="order_item.product_variation_item">
              {{order_item.glass.name}} ({{order_item.product_variation_item.name}})
            </span>
            <span v-else>
              {{order_item.glass.name}}
            </span>
            <br />
            <b>Quantité : </b>{{order_item.quantity}}<br />
            <div >
              <b>Informations personnalisation</b><br />
         

              <span v-for="order_item_customization in order_item.order_item_customizations" :key="order_item_customization.id">
                <span v-if="order_item_customization.model_part.type=='color'">Couleur {{order_item_customization.model_part.name}} : {{order_item_customization.color.name}}<br /></span>
                <!-- <span v-if="order_item_customization.model_part.render_type=='lense'">Teinte de verre : {{order_item_customization.tint ? order_item_customization.tint.name : "Transparent"}}<br /></span> -->
              </span>
              <span>Teinte de verre : {{order_item.tint ? order_item.tint.name : "Transparent"}}<br /></span>
              <span v-if="!order_item.order_item_customizations">

                <span v-if="order_item.glass_type">Type de verre : {{order_item.glass_type.name}}<br /></span>
                <span v-if="order_item.glass_option">Option de verre : {{order_item.glass_option.name}}<br /></span>
                <span v-if="order_item.color">Couleur : {{order_item.color.name}}<br /></span>
                <span v-if="order_item.tint">Teinte de verres : {{order_item.tint.name}}<br /></span>
                <span v-if="order_item.branch">Branche : {{order_item.branch.name}}<br /></span>
                <span v-if="order_item.branch_color">Couleur de Branche : {{order_item.branch_color.name}}<br /></span>
                <span v-if="order_item.branch_texture">Texture de Branche : {{order_item.branch_texture.name}}<br /></span>
                <span v-if="order_item.texture">Texture : {{order_item.texture.name}}<br /></span>
                <span v-if="order_item.texture_style">Style de texture : {{order_item.texture_style.name}}<br /></span>
                <span v-if="order_item.refinement">Affinement : {{order_item.refinement.name}}<br /></span>
                <span v-if="order_item.processing">Traitement : {{order_item.processing.name}}<br /></span>
                
                
              </span>

              <span v-if="order_item.engraving_text">Gravure : {{order_item.engraving_text}}<br /></span>
              <span v-else>Gravure : sans gravure<br /></span>
              
            
              <div v-if="order_item.scan!=null">
                Scan : {{order_item.scan.name}} <br />
              
                <b-button @click="$router.push({name:'scanDetail', params: { id: order_item.scan.id }})">Voir le scan</b-button>
              </div>
              <div v-else-if="order_item.size!=null">
                Taille : {{order_item.size}} <br />
             
              </div>
              <div v-if="order_item.prescription!=null">
                Ordonnance : {{order_item.prescription.name}} (<a @click="displayPrescription(order_item.prescription.id)">Voir</a>)<br />
              </div>
            </div>
            <b>Total : </b>{{order_item.amount}}€<br /><br />
          </b-card-text>
        </b-card>

        <ScanPopup :scan_id="currentScan" v-if="displayScanPopup" />
        <PrescriptionPopup :prescription_id="currentPrescription" v-if="displayPrescriptionPopup" />
  </b-row>
</div>
</b-overlay>
</template>

<script>
import {BModal, BButton,BOverlay, VBModal, BRow, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest, Woocommerce} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ScanPopup from '../../components/ScanPopup.vue'
import PrescriptionPopup from '../../components/PrescriptionPopup.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import emitter from 'tiny-emitter/instance'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BOverlay,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    BRow,
    ScanPopup,
    PrescriptionPopup
  },
  data() {
    return {
      order:null,
      displayStatusSelection:false,
      statusSelected:null,
      statusOptions:['pending','on-hold','failed','completed','processing','cancelled','refunded','trash'],
      pageLength: 10,
      dir: false,
      photos:null,
      currentScan:null,
      currentPrescription:null,
      displayPrescriptionPopup:false,
      displayScanPopup:false,
      loading:true,
      trackingNumber:''
    }
  },
  methods:{
    displayScan(id) {
      this.currentScan=id
      this.displayScanPopup=true
    },
    displayPrescription(id) {
      this.currentPrescription=id
      this.displayPrescriptionPopup=true
    },
    async getOrder() {
      let order=await APIRequest.detail('orders',this.$route.params.id)
      this.order={}
      this.order=order
      console.log('order')
      console.log(this.order)
      if(this.order.woo_order)
      {
        let order = await Woocommerce.detail('orders',this.order.woo_order)
        let metas = order.meta_data
        
        for(let i=0;i<metas.length;i++)
        {
          if(metas[i].key=="tracking_number")
          {
            this.trackingNumber=metas[i].value
          }
        }

        this.order.status=order.status
        this.statusSelected=order.status
      }
      else
      {
        this.order.status='completed'
      }
      if(!this.order.user)
      {
        this.order.user={}
        this.order.user.name=''
        this.order.user.surname='Inconnu'
      }
      this.photos=[]
    
    },
    downloadFiles() {
      var str = this.order.file1 // Export the scene
      var blob = new Blob( [str], { type : 'text/plain' } ); // Generate Blob from the string
      console.log(str)
      var link = document.createElement('a');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.href = URL.createObjectURL(blob);
      link.download = 'Scene.stl';
      link.click();

      var str2 = this.order.file2; // Export the scene
      var blob2 = new Blob( [str2], { type : 'text/plain' } ); // Generate Blob from the string
      //saveAs( blob, 'file.stl' ); //Save the Blob to file.stl
 console.log(str2)
      var link2 = document.createElement('a');
      link2.style.display = 'none';
      document.body.appendChild(link2);
      link2.href = URL.createObjectURL(blob2);
      link2.download = 'Scene 2.stl';
      link2.click();

      var str3 = this.order.file3; // Export the scene
      var blob3 = new Blob( [str3], { type : 'text/plain' } ); // Generate Blob from the string
      var link3 = document.createElement('a');
       console.log(str3)
      link3.style.display = 'none';
      document.body.appendChild(link3);
      link3.href = URL.createObjectURL(blob3);
      link3.download = 'Scene 3.stl';
      link3.click();
    }
    
  },
  watch:{
    
  },
  async mounted()
  {
    this.loading=true
    emitter.on('hide-popup-scan', function () {
      this.displayScanPopup=false
    }, this);
    emitter.on('hide-popup-prescription', function () {
      this.displayPrescriptionPopup=false
    }, this);
    await this.getOrder()
    this.loading=false
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
